import React from "react";

const Whychooseus = () => {
  return (
    <div className="bg-[#fffdfd]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-5 gap-6 text-center">
          <div className="bg-[var(--accent)] text-[var(--accent-foreground)] p-6 border-r-2 border-gray-300">
            <p className="sm:text-2xl md:text-6xl font-bold text-[#fead26]">
              8
            </p>
            <p className="text-md md:font-bold">Years Experience</p>
          </div>
          <div className="bg-[var(--accent)] text-[var(--accent-foreground)] p-6 border-r-2 border-gray-300">
            <p className="sm:text-2xl md:text-6xl font-bold text-[#fead26]">
              100+
            </p>
            <p className="text-md md:font-bold">Awards Received</p>
          </div>
          <div className="bg-[var(--accent)] text-[var(--accent-foreground)] p-6 border-r-2 border-gray-300">
            <p className="sm:text-2xl md:text-6xl font-bold text-[#fead26]">
              1000+
            </p>
            <p className="text-md md:font-bold">Happy Clients</p>
          </div>
          <div className="bg-[var(--accent)] text-[var(--accent-foreground)] p-6 border-r-2 border-gray-300">
            <p className="sm:text-2xl md:text-6xl font-bold text-[#fead26]">
              40+
            </p>
            <p className="text-md md:font-bold">Developers</p>
          </div>
          <div className="bg-[var(--accent)] text-[var(--accent-foreground)] p-6 border-r-2 border-gray-300">
            <p className="sm:text-2xl md:text-6xl font-bold text-[#fead26]">
              100+
            </p>
            <p className="text-md md:font-bold">Projects</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whychooseus;
